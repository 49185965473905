<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Sucursales
        </h3>
        <p class="ml-1">
          Administra los lugares donde vendes tus productos, realizas ordenes y
          manejas inventarios
        </p>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-card class="card_background">
          <v-container class="px-5">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                <p class="caption ma-0">
                  Actualmente cuentas con
                  <v-progress-circular
                    indeterminate
                    size="14"
                    width="2"
                    v-if="loaderTotalBusiness"
                  ></v-progress-circular>
                  <span v-else class="font-weight-bold">{{
                    businessData.state.branch_offices
                  }}</span>
                  de
                  <v-progress-circular
                    indeterminate
                    size="14"
                    width="2"
                    v-if="loaderTotalBusiness"
                  ></v-progress-circular>
                  <span v-else class="font-weight-bold">{{
                    businessData.plan.branch_offices
                  }}</span>
                  sucursales disponibles en tu plan.
                  <a
                    @click="$router.push({ name: 'billingAndPlan' })"
                    class="text-decoration-none"
                    >Conoce tu plan</a
                  >
                </p>
              </v-col>
              <v-slide-x-transition leave-absolute>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="8"
                  xl="8"
                  class="pb-0"
                  v-if="!loader"
                >
                  <template v-for="branchOffice in getBranchOffices">
                    <v-card
                      class="d-flex align-center pa-2 mb-2"
                      outlined
                      link
                      :key="branchOffice.id"
                      :to="`/ajustes/sucursales/${branchOffice.id}/editar`"
                    >
                      <div>
                        <v-icon large>store</v-icon>
                      </div>
                      <div style="width:100%">
                        <p class="mb-1 px-2 text-body-1">
                          {{ branchOffice.branch_office_name }}
                          <span
                            v-if="getCurrentBranchOffice.id === branchOffice.id"
                            ><v-chip x-small>Predeterminada</v-chip></span
                          >
                        </p>
                        <div>
                          <p class="ma-0 px-2 caption">
                            {{ branchOffice.location.street_name }}, #{{
                              branchOffice.location.street_number
                            }}, {{ branchOffice.location.city_name }},
                            {{ branchOffice.location.state_name }}, México,
                            {{ branchOffice.location.postal_code }}
                          </p>
                        </div>
                      </div>
                    </v-card>
                  </template>
                </v-col>
              </v-slide-x-transition>
              <v-col cols="12" sm="12" md="12" lg="8" xl="8" v-if="loader">
                <v-skeleton-loader
                  type="list-item-three-line, list-item-three-line, list-item-three-line"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" class="text-right" v-if="isAdminUser">
                <v-btn
                  class="success"
                  :loading="loaderTotalBusiness"
                  :disabled="validAddMore"
                  :to="{ name: 'branchOfficeRegisterSettings' }"
                  >Agregar sucursal</v-btn
                >
                <div v-if="!loaderTotalBusiness" class="text-center">
                  <v-btn
                    class="mt-2 text-subtitle-2 white--text"
                    color="teal"
                    block
                    v-if="validAddMore"
                    @click="$router.push({ name: 'billingAndPlan' })"
                  >
                    ¿Deseas agregar más sucursales?
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loader: true,
      loaderTotalBusiness: true,
      businessData: {},
      validAddMore: false
    };
  },
  async mounted() {
    this.getBusinessMetadata();
    this.loader = true;
    await this.getBranchOfficeData();
    this.loader = false;
  },
  methods: {
    ...mapGetters("user", ["isAdminUser"]),
    ...mapActions("business", [
      "getBranchOfficeData",
      "getBusinessPlan",
      "getBusinessState"
    ]),
    async getBusinessMetadata() {
      this.loaderTotalBusiness = true;
      const businessPlan = await this.getBusinessPlan();
      const businessState = await this.getBusinessState();
      this.businessData.plan = businessPlan;
      this.businessData.state = businessState;
      if (
        this.businessData.state.branch_offices >=
        this.businessData.plan.branch_offices
      )
        this.validAddMore = true;
      this.loaderTotalBusiness = false;
    }
  },
  computed: {
    ...mapGetters("business", ["getBranchOffices", "getCurrentBranchOffice"])
  }
};
</script>

<style scoped>
.link-question {
  cursor: pointer !important;
  text-decoration: underline !important;
}
.link-question:hover {
  color: #455a64 !important;
}
</style>
